<!-- 联动选择商户与门店 -->
<template>
    <div style="display: flex;align-items: center;">
        <el-row :gutter="10">
            <el-col :span="12"><el-select v-model="tableParams.merchantId" placeholder="请选择商户" @change="onMerchantSel"
                    clearable class="mr15">
                    <el-option v-for="item in commercialList" :key="item.merchantId" :label="item.merchantName"
                        :value="item.merchantId">
                    </el-option>
                </el-select></el-col>
            <el-col :span="12">
                <el-select v-model="tableParams.shopId" placeholder="请选择门店" clearable @change="onShopSel" class="mr15">
                    <el-option v-for="item in tableShopList" :key="item.shopId" :label="item.shopName"
                        :value="item.shopId">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import { getAutoCommercialList } from "@/api/commercial/commercial";
import { getIdShopList } from "@/api/commercial/shop";
export default {
    data() {
        return {
            commercialList: [], // 商户列表
            tableShopList: [], //店铺列表
            tableParams: {
                merchantId: '',
                shopId: '',
            }
        }
    },
    watch: {

    },
    mounted() {
        this.getAutoCommercialList(); // 【请求】商户下拉列表
    },
    methods: {
        // 【请求】商户下拉列表
        getAutoCommercialList() {
            getAutoCommercialList().then((res) => {
                if (res.isSuccess == "yes") {
                    this.commercialList = res.data;
                    if (res.data && res.data.length > 0) {
                        this.tableParams.merchantId = res.data[0].merchantId;
                        this.getTableIdShopList();
                    } else {
                        this.tableParams.merchantId = "";
                        this.tableShopList = [];
                        this.tableParams.shopId = "";
                        this.$emit('reload', this.tableParams)
                    }
                }
            });
        },
        // 【请求】筛选商户店铺列表
        getTableIdShopList() {
            let data = {
                merchantId: this.tableParams.merchantId,
            };
            getIdShopList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    this.tableShopList = res.data;
                    if (res.data.length > 0) {
                        this.tableParams.shopId = res.data[0].shopId;
                    } else {
                        this.tableParams.shopId = "";
                    }
                    this.$emit('reload', this.tableParams)
                }
            });
        },
        // 【监听】筛选商户
        onMerchantSel() {
            this.tableParams.shopId = "";
            if (this.tableParams.merchantId) {
                this.getTableIdShopList();
            } else {
                this.tableShopList = [];
                this.tableParams.shopId = "";
                this.$emit('reload', this.tableParams)
            }
        },
        // 【监听】筛选店铺
        onShopSel() {
            this.$forceUpdate();
            this.$emit('reload', this.tableParams)
        },
    }
}

</script>
<style scoped lang="scss"></style>
